import React, {Component, useState } from 'react';
import logo from './img/Telth_logo.png';
import './App.css';

const d = new Date();
let year = d.getFullYear();
function App() {
  // const [sidemenu, setSidemenu] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [curlyOpen, setCurlyOpen] = useState("{");
  const [curlyClose, setCurlyClose] = useState("}");
  const [yearFetch, setYearFetch] = useState(year);

  // const sidemenuopen = () => {
  //   setSidemenu(true) 
  // };

  // const sidemenuclose = () => {
  //   setSidemenu(false) 
  // };

  const navbarbackground = () =>{
    if(window.scrollY >= 130){
      setNavbar(true);
    }else{  
      setNavbar(false);
    }
  }

  window.addEventListener('scroll', navbarbackground);

  return (
    <div className="telth_landingpage">

    {/* Navbar section start */}
    
    <section id="navbar-section">
      <div className="top-nav">
        <div className="row">
          <div className="col-6">
            <div className="nav-mail-num">
              <span className="mx-2"><i className="fa fa-envelope" aria-hidden="true"></i> &nbsp;hello @ telth.com</span>
              <span className="mx-2"><i className="fa fa-phone" aria-hidden="true"></i> &nbsp;+91 720 00 TELTH</span>
            </div>
          </div>
          <div className="col-6"  style={{display:'flex', justifyContent:'flex-end', color:'#2e2e4c'}}>
            <div className="social-media-icon-top">
              <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>|
              <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a> |
              <a href="#"><i className="fa fa-snapchat-ghost" aria-hidden="true"></i></a>|
              <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
              </div>
          </div>
        </div>
      </div>
     <div className={navbar ? "telth_landingpage_navbar navbar-bg fixed-top" : "telth_landingpage_navbar"}>
     <nav className="navbar navbar-toggleable-md navbar-expand-lg navbar-light sticky-top" id="mainNav">
    <div className="container-fluid navbar-container" style={{fontFamily: 'poppins'}}>
      <a className="navbar-brand js-scroll-trigger" href="#" style={{fontFamily: 'poppins'}}>
      <img src={logo} width="60px"/>
      <span className="logo-text">
        TELTH <span className="" style={{fontWeight:'400'}}>SYSTEMS</span>
      </span>
      </a>
      <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa fa-bars"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ml-auto" style={{alignItems: "center"}}>
            <li className="nav-item">
                <a className="nav-link" href="#"><i className="fa fa-home"></i></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#our-services-section">Services</a>
              </li>
          <li className="nav-item">
            <a className="nav-link " href="#technology-section">Technology</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#whyus-section">Why Us</a>
          </li>
          <li className="nav-item contact-info">
            <a className="nav-link " href="#contact-section">Contact Us</a>
          </li>
        </ul>
      
      </div>
    </div>
  </nav>
       
      </div>
    </section>
    
      {/* Navbar section end */}
     
      {/* Header section start */}

      <section id="header-section">
       <header className="telth_landingpage_header">
       <div className="header-details">
         <div className="row m-0 header-contents">
           <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-8" style={{display:'flex', justifyItems:'center', alignItems:'center'}}>
           <div className="header-content-btn">
           <div className="content">
             
            {/* <span className="subtext-top py-2">WE ARE PROVIDING THESE SERVICES</span> */}
                <div id="textSlider">
                  <div className="slideCol">
                    <div className="scroller">
                      <div className="inner">
                        <p>Zero to MAP in 100 days</p>
                        <p>Software Development</p>
                        <p>Mobile App Development</p>
                        <p>Marketing Consulting</p>
                      </div>
                    </div>
                  </div>
          
                </div>
          
	
              {/* <h4 className="py-2 ">Design & Development Services Provider</h4> */}
              <div className="header-bottom-text" style={{maxWidth:'70%', marginTop: '10px'}}>
              <span className="subtext-bottom py-3">IT / Technology Services of software development, mobile app development & search engine optimization.
              </span>
              </div>
           </div>
           <div className="view-project-btn my-5" style={{display:"flex"}}>
             <div className="" style={{alignSelf:'center'}}>
             <a href="#ourproject-section" className="header-viewmore-btn my-3">GET STARTED &nbsp;<i className="fa fa-angle-double-right" aria-hidden="true"></i></a>
             </div>
          
           {/* <div className="play-btn ml-5 play-mobileview">
             <div className="play-btn-bg">
             <div className="play">
                <i className="fa fa-play" aria-hidden="true"></i>
                </div>
             </div>
              
             </div> */}
             </div>
         </div>
           </div>
           {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 video-play-btn play-largeview">
           <div className="header-img">
            <div className="image">
              <div className="play-btn ml-5">
                <div className="play-btn-bg">
                <div className="play">
                    <i className="fa fa-play" aria-hidden="true"></i>
                    </div>
                </div>
             </div>
            </div>
         </div>
             </div> */}
             
         </div>
        
        <div className="overlay"></div>
        </div>
      </header>
      
      </section>
     
     {/* Header section end */}

    
       {/* our services section start */}
     
    <section id="our-services-section">
    <div className="ourservices-details">
        <div className="our-services-content-btn">
        <section className="">
          <div className="container">
          <div className="row pb-2">
                  <div className="col-12">
                  <div className="our-services-content text-center">
                  {/* <span className="subtext-top py-2">WE ARE PROVIDING THESE SERVICES</span> */}
                  {/* <img className="mt-3" src="./img/telth-logo.png" width="70px"/> */}
                    <h4 className="py-2">Innovation-led applications services to our customers </h4>
                    {/* <span className="subtext-bottom py-2">We’re an interdisciplinary team with experience in all kinds of projects. 
                    We are experts in usability, user experience, visual design, 
                    programming and we’ll take care of everything you need for your project to succeed.
                    </span> */}
                    <span className="subtext-bottom py-2">Our innovative solutions help customers achieve their business objectives by 
                    providing the best-in-class consulting, IT solutions and services. we help you give a better experience to your users
                     and thereby set new standards
                    </span>
                   </div>
                  </div>
              
            </div>
          </div>
        </section>
        <div className="our-services-columns  my-3">
        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-1 p-2">
                <div className="services-sections">
                    <div className="serives-icon-title">
                      <div className="">
                        <div className="services-icon">
                        <img alt="services" src='./img/services/mvp.png'/>
                        </div>
                      
                      </div>
                      <div className="services-title">
                      <h5>Zero to MAP in 100 days</h5>
                      </div>
                    </div>
                    <div className="services-content">
                      <span>We build your ideas. We are specialists in startups and MAP development for mobile and web.
                          We help turn your ideas into reality with a Minimum Awesome Product (MAP).
                          <br/><br/>
                          We will work with you & your team every step until we turn it into reality.
                          </span>
                    </div>
                  </div>
            
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-1 p-2">
                <div className="services-sections">
                  <div className="serives-icon-title">
                  <div className="">
                      <div className="services-icon">
                      <img alt="services" src="./img/services/software.png"/>
                      </div>
                    
                    </div>
                    <div className="services-title">
                    <h5>Software development</h5>
                    </div>
                  </div>
                  <div className="services-content">
                    <span>
                    Our software development and consulting services help you pull off your next project quickly with less stress and less cost. 
                    <br/><br/>
                    Our team has the technology 
                    prowess to power you at every stage of your product development lifecycle, 
                    from discovery to ongoing post-launch enhancement.
                    </span>
                  </div>
                </div>
            
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-1 p-2">
                <div className="services-sections">
                  <div className="serives-icon-title">
                  <div className="">
                      <div className="services-icon">
                      <img alt="services" src="./img/services/mobile.png"/>
                      </div>
                    
                    </div>
                    <div className="services-title">
                    <h5>Mobile app development</h5>
                    </div>
                  </div>
                  <div className="services-content">
                    <span>
                    Our team of mobile consultants and mobile app development experts have handled and delivered successfully.
                    
                    and on time over 200 
                    mobile apps for a diverse range of clients across different industries.
                    </span>
                  </div>
                </div>
          
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-1 p-2">
                <div className="services-sections">
                  <div className="serives-icon-title">
                    <div className="">
                      <div className="services-icon">
                      <img alt="services" src="./img/services/planning.png"/>
                      </div>
                    
                    </div>
                    <div className="services-title">
                    <h5>Strategic planning</h5>
                    </div>
                  </div>
                  <div className="services-content">
                    <span>
                    We at TELTH help build your custom strategy based on your environment 
                    and market opportunities.  Our support staff and expert strategists create a custom toolset so you can monitor progress and sync daily decision with long-term focus. 
                    Enabling you to buid a plan and move to implementation fast.
                    </span>
                  </div>
                </div>
            
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-1 p-2">
                <div className="services-sections">
                  <div className="serives-icon-title">
                  <div className="">
                      <div className="services-icon">
                      <img alt="services" src="./img/services/market.png"/>
                      </div>
                    
                    </div>
                    <div className="services-title">
                    <h5>Marketing consulting</h5>
                    </div>
                  </div>
                  <div className="services-content">
                    <span>
                    Our experienced marketing consultants help your business to create and implement marketing strategies centered on your core business model. 
                    Our expertise in consumer behavior and teh market process enable us to 
                  strategically position your product.
                    </span>
                  </div>
                </div>
            
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-1 p-2">
                <div className="services-sections">
                    <div className="serives-icon-title">
                    <div className="">
                        <div className="services-icon">
                        <img alt="services" src="./img/services/support.png"/>
                        </div>
                      
                      </div>
                      <div className="services-title">
                      <h5>Support</h5>
                      </div>
                    </div>
                    <div className="services-content">
                      <span>
                      Our 24x7 support service are built to increase efficiency and reduce operating costs ensuring customer satisfaction.
    You get hands-on help with the most complicated and urgent of tasks.
    We are here to help.
                      </span>
                    </div>
                  </div>
              
                </div>

            </div>
        </div>
          </div>
      
        </div>
      </div>
      </section>

     {/* our services section end */}

       {/* Technology section start */}

       <section id="technology-section">
        <div className="technology-details">
          <div className="technology-title container">
            
              <div className="technology-title-sub">
              <h4>Technology</h4>
                <span className="subtext-bottom">The right technology stack to build better software faster</span>
              </div>
              <div className="technology-title-tools">
              <div className="technology-tools">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-3 col-lg-4 col-xl-4 tools-bg">
                    <div className="tools-title">
                      <img src='./img/tools/cloud.png' width="150px"/>
                      <h5>Cloud</h5>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-9 col-lg-8 col-xl-8">
                    <div className="tools-logo1">
                    <img className="p-2" alt="amazon" src='./img/tools/amazon.png'/>
                      <img className="p-2" alt="digitalocean" src='./img/tools/digitalocean.png'/>
                      <img className="p-2" alt="google cloud" src='./img/tools/googlecloud.png'/>
                      <img className="p-2" alt="heroku" src='./img/tools/heroku.png'/>
                      <img className="p-2" alt="microsoft azure" src='./img/tools/mz.png'/>
                    </div>
                  </div>
                </div>
            </div>
            <hr/>

            <div className="technology-tools">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-3 col-lg-4 col-xl-4 tools-bg">
                    <div className="tools-title">
                    <img src='./img/tools/cms.png' width="150px"/>
                      <h5>CMS</h5>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-9 col-lg-8 col-xl-8">
                    <div className="tools-logo1">
                      <img className="p-2" alt="drupal" src='./img/tools/drupal.png'/>
                      <img className="p-2" alt="joomla" src='./img/tools/joomla.png'/>
                      <img className="p-2" alt="wordpress" src='./img/tools/wordpress.png'/>
                      <img className="p-2" alt="php" src='./img/tools/php.png'/>
                      <img className="p-2" alt="magento" src='./img/tools/magento.png'/>
                    </div>
                  </div>
                </div>
            </div>
            <hr/>

            <div className="technology-tools">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-3 col-lg-4 col-xl-4 tools-bg">
                    <div className="tools-title">
                    <img src='./img/tools/iot.png' width="150px"/>
                      <h5>IOT</h5>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-9 col-lg-8 col-xl-8">
                    <div className="tools-logo1">
                      <img className="p-2" alt="java" src='./img/tools/java.png'/>
                      <img className="p-2" alt="alt" src='./img/tools/alt.png'/>
                      <img className="p-2" alt="arduino" src='./img/tools/arduino.png'/>
                      <img className="p-2" alt="ibeacon" src='./img/tools/ibeacon.png'/>
                      <img className="p-2" alt="raspi" src='./img/tools/raspi.png'/>
                    </div>
                  </div>
                </div>
            </div>
            <hr/>

            <div className="technology-tools">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-3 col-lg-4 col-xl-4 tools-bg">
                    <div className="tools-title">
                    <img src='./img/tools/mobile.png' width="150px"/>
                      <h5>Mobile</h5>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-9 col-lg-8 col-xl-8">
                    <div className="tools-logo2">
                      
                      
                      <img className="p-2" alt="android" src='./img/tools/android.png'/>
                      <img className="p-2" alt="ios" src='./img/tools/ios.png'/>
                      <img className="p-2" alt="html" src='./img/tools/html.png'/>
                      <img className="p-2" alt="css" src='./img/tools/css.png'/>
                      <img className="p-2" alt="JavaScript" src='./img/tools/JavaScript.png'/>

                      <img className="p-2" alt="firebase" src='./img/tools/firebase.png'/>
                      <img className="p-2" alt="angular" src='./img/tools/angular.png'/>
                      <img className="p-2" alt="Cordova" src='./img/tools/Cordova.png'/>
                      <img className="p-2" alt="Ionic" src='./img/tools/Ionic.png'/>
                      <img className="p-2" alt="java" src='./img/tools/java.png'/>

                      <img className="p-2" alt="kotlia" src='./img/tools/kotlia.png'/>
                      <img className="p-2" alt="node" src='./img/tools/node.png'/>
                      <img className="p-2" alt="reactnative" src='./img/tools/reactnative.png'/>
                      <img className="p-2" alt="sass" src='./img/tools/sass.png'/>
                      <img className="p-2" alt="swift" src='./img/tools/swift.png'/>

                      <img className="p-2" alt="unity" src='./img/tools/unity.png'/>
                      <img className="p-2" alt="xamarin" src='./img/tools/xamarin.png'/>
                      <img className="p-2" alt="xml" src='./img/tools/xml.png'/>
                    </div>
                  </div>
                </div>
            </div>
            <hr/>

            <div className="technology-tools">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-3 col-lg-4 col-xl-4 tools-bg">
                    <div className="tools-title">
                    <img src='./img/tools/web.png' width="150px"/>
                      <h5>Web</h5>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-9 col-lg-8 col-xl-8">
                    <div className="tools-logo2">
                      <img className="p-2" alt="angular" src='./img/tools/angular.png'/>
                      <img className="p-2" alt="codeigniter" src='./img/tools/codeigniter.png'/>
                      <img className="p-2" alt="django" src='./img/tools/django.png'/>
                      <img className="p-2" alt="firebase" src='./img/tools/firebase.png'/>
                      <img className="p-2" alt="java" src='./img/tools/java.png'/>

                      <img className="p-2" alt="laravel" src='./img/tools/laravel.png'/>
                      <img className="p-2" alt="mongodb" src='./img/tools/mongodb.png'/>
                      <img className="p-2" alt="mysql" src='./img/tools/mysql.png'/>
                      <img className="p-2" alt="python" src='./img/tools/python.png'/>
                      <img className="p-2" alt="node" src='./img/tools/node.png'/>

                      <img className="p-2" alt="html" src='./img/tools/html.png'/>
                      <img className="p-2" alt="css" src='./img/tools/css.png'/>
                      <img className="p-2" alt="JavaScript" src='./img/tools/JavaScript.png'/>
                      <img className="p-2" alt="markdown" src='./img/tools/markdown.png'/>
                      <img className="p-2" alt="php" src='./img/tools/php.png'/>
                      
                      <img className="p-2" alt="react" src='./img/tools/react.png'/>
                      <img className="p-2" alt="sass" src='./img/tools/sass.png'/>
                      <img className="p-2" alt="spring" src='./img/tools/spring.png'/>
                    </div>
                  </div>
                </div>
            </div>

           </div>
          </div>
         
        </div>
       </section>
     
     {/* Technology section end */}


       {/* why us section start */}

      <section id="whyus-section">
        <div className="whyus-details">
          <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 whyus-bg my-1">
                <div className="why-title-bg my-2">
                    <h4>Why choose us?</h4>
                    {/* <span className="text-white">We at TELTH provide a range of flexible solutions and services</span> */}
                    <div className="whyus-more-btn">
                      <a className="moredet-btn" href="https://appsinventor.com/" target="_blank"><i className="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                   </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 whyus-bg my-1">
                   
                  <div className="whyus-section-list mt-2">
                    <div className="whyus-icon-title">
                      <img className="my-1" src='./img/whyus/Whyus-1.png' height="70px"/>
                      <h5>Experience and Expertise</h5>
                    </div>
                    <div className="whyus-content my-2">
                    <span>
                    We at TELTH provide a range of flexible solutions and services designed to meet our clients' requirements. We have a proven track record in 
                    the successful implementation of mission-critical business applications.
                      </span>  
                    </div> 
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 whyus-bg my-1">
                    <div className="whyus-section-list mt-2">
                    <div className="whyus-icon-title">
                      <img className="my-1" src='./img/whyus/Whyus-2.png' height="70px"/>
                      <h5>Professional team</h5>
                    </div>
                    <div className="whyus-content my-2">
                    <span>
                    Our team of Project Managers, Designers, Developers and Testers work on-site or at our Development Center. 
                    We also provide a complete outsourced software development service.
                    </span>  
                    </div> 
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 whyus-bg my-1">
                <div className="whyus-section-list mt-2">
                <div className="whyus-icon-title">
                  <img className="my-1" src='./img/whyus/Whyus-3.png' height="70px"/>
                  <h5>Business experience</h5>
                </div>
                <div className="whyus-content my-2">
                <span>
                We specialize in providing custom Business Application (Data Management, Business Intelligence, Sales and Finance) to increase productivity, 
                improve competitiveness and achieve business growth.
                 </span>  
                </div> 
              </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 whyus-bg my-1">
                <div className="whyus-section-list mt-2">
                <div className="whyus-icon-title">
                  <img className="my-1" src='./img/whyus/Whyus-4.png' height="70px"/>
                  <h5>Solution oriented</h5>
                </div>
                <div className="whyus-content my-2">
                <span>
                We provide system integration services to ensure that our software 
                solutions fit seamlessly into the client's existing IT infrastructure. We have proven experience in integrating different proprietary systems for ERP, CRM, SCM, Data Warehousing, Access Management, 
                Credit Checking, Print Services, Workflow, Reporting and Analytics.
                 </span>  
                </div> 
              </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 whyus-bg my-1">
                <div className="whyus-section-list mt-2">
                <div className="whyus-icon-title">
                  <img className="my-1" src='./img/whyus/Whyus-5.png' height="70px"/>
                  <h5>Business understanding</h5>
                </div>
                <div className="whyus-content my-2">
                <span>
                We at TELTH help our clients by designing, building and maintaining business-focussed websites and portals. We offer customized Web Application Development, Website Optimization, 
                Portal Development, Search Engine Optimization and API Integration.
                </span>  
                </div> 
              </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 whyus-bg my-1">
                <div className="whyus-section-list mt-2">
                <div className="whyus-icon-title">
                  <img className="my-1" src='./img/whyus/Whyus-6.png' height="70px"/>
                  <h5>Process quality</h5>
                </div>
                <div className="whyus-content my-2">
                <span>
                We ensure successful and effective delivery of each project by managing the software development within a controlled and proven process - 
                all governed by a set of industry standard development methodologies.
                 </span>  
                </div> 
              </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 whyus-bg my-1">
                <div className="whyus-section-list mt-2">
                <div className="whyus-icon-title">
                  <img className="my-1" src='./img/whyus/Whyus-7.png' height="70px"/>
                  <h5>Technology leverage</h5>
                </div>
                <div className="whyus-content my-2">
                <span>
                We incorporate the right technology stack to belief better 
                software faster to drive maximum value to your business.
                </span>  
                </div> 
              </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 whyus-bg my-1">
                <div className="whyus-section-list mt-2">
                <div className="whyus-icon-title">
                  <img className="my-1" src='./img/whyus/Whyus-8.png' height="70px"/>
                  <h5>Customer-focused</h5>
                </div>
                <div className="whyus-content my-2">
                <span>
                We at TELTH also offer Social Media Development to help connect, 
                engage and publish businesses. We connect globally with clients and partners using social media - Twitter, Facebook, LinkedIn, 
                YouTube, Pinterest. We also can help manage your Online Reputation.
                </span>  
                </div> 
              </div>
                </div>
              </div>
          </div>
         
        </div>
       </section>
     
     {/* why us section end */}

       {/* our project section start */}

      <section id="ourproject-section">
        
      <div className="ourproject-details">
      <div className="container">
      <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="ourproject-title">
              <span className="subtext-top">Mobile & Web App, Websites</span>
              <h4>Recent portfolio showcase</h4>
              <span className="subtext-bottom">
              The power of innovation and commitment to make the best Mobile/ Web App and websites that bring success.  
              </span>
              <div className="ourproject-btn my-5">
                <a href="https://appsinventor.com/products.html" target="_blank" className="ourproject-list-btn text-uppercase">View all works &nbsp;<i className="fa fa-angle-double-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ourproject-bg">
            <div className="ourproject-images" style={{justifyContent:'center'}}>
              <div className="project-list">
                <div className="project-bg">
                  <img src='./img/project/project-1.png' width="100%"/>
                </div>
                <div className="project-bg">
                <img src='./img/project/project-2.png' width="100%"/>
                </div>
              </div>
              <div className="project-list">
                <div className="project-bg">
                <img src='./img/project/project-3.png' width="100%"/>
                </div>
                <div className="project-bg">
                <img src='./img/project/project-4.png' width="100%"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </section>
    
     {/* our project section end */}

        {/* Our partners section start */}


        <section id="ourpartners-section">
        <div className="ourpartners-details">
          <div className="ourpartners-title container">
            <div className="row">

              <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
              <h4>Our partners</h4>
            
              </div>
            </div>
            
          </div>
          <div className="ourpartners-logo-section container mt-5">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-0">
                <div className="product-logo-title">
                  <a href="https://appsinventor.com/products.html" target="_blank">
                  <div className="productslogo-list">
                  <img className="mx-2" src='./img/partners/Aquilaetech_logo.png'/>
                  </div>
                  </a>
                <div className="product-content">
                 <h6 style={{cursor: 'pointer', fontFamily: 'roboto condensed', fontWeight:'600', fontSize: '18px'}}><a style={{color: '#1D1A1A'}} href="https://appsinventor.com/products.html" target="_blank">Aquila Technologies</a></h6>  
                    </div>
                </div>
              
            </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-0">
              <div className="product-logo-title">
                <a href="https://appsinventor.com/products.html" target="_blank">
                  <div className="productslogo-list">
                    <img className="mx-2" src='./img/partners/ats.png'/>
                    </div>
                    </a>
                  <div className="product-content">
                       <h6 style={{cursor: 'pointer', fontFamily: 'roboto condensed', fontWeight:'600', fontSize: '18px'}}><a style={{color: '#1D1A1A'}} href="https://appsinventor.com/products.html" target="_blank">Samhit Assessments and
                  Research Foundation</a></h6> 
                      </div>
              </div>
             
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-0">
              <div className="product-logo-title">
                <a href="https://appsinventor.com/products.html" target="_blank">
                  <div className="productslogo-list">
                    <img className="mx-2" src='./img/partners/bravish.png' width="100px"/>
                    </div>
                    </a>
                  <div className="product-content">
                  <h6 style={{cursor: 'pointer', fontFamily: 'roboto condensed', fontWeight:'600', fontSize: '18px'}}><a style={{color: '#1D1A1A'}} href="https://appsinventor.com/products.html" target="_blank">Bravish Technologies Pvt Ltd</a></h6> 
                      </div>
              </div>
              
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-0">
              <div className="product-logo-title">
                <a href="https://appsinventor.com/products.html" target="_blank">
                <div className="productslogo-list">
                  <img className="mx-2" src='./img/partners/broadline.png'/>
                  </div>
                  </a>
                <div className="product-content">
                <h6 style={{cursor: 'pointer', fontFamily: 'roboto condensed', fontWeight:'600', fontSize: '18px'}}><a style={{color: '#1D1A1A'}} href="https://appsinventor.com/products.html" target="_blank">Broadline Technologies Pvt Ltd</a></h6> 
                    </div>
              </div>
            
            </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-0">
              <div className="product-logo-title">
                <a href="https://appsinventor.com/products.html" target="_blank">
                  <div className="productslogo-list">
                    <img className="mx-2" src='./img/partners/moon.png'/>
                    </div>
                    </a>
                  <div className="product-content">
                      <h6 style={{cursor: 'pointer', fontFamily: 'roboto condensed', fontWeight:'600', fontSize: '18px'}}><a style={{color: '#1D1A1A'}} href="https://appsinventor.com/products.html" target="_blank">Moon Healthcare Pvt Ltd</a></h6>
                    </div>
              </div>
              
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-0">
              <div className="product-logo-title">
                <a href="https://appsinventor.com/products.html" target="_blank">
                  <div className="productslogo-list">
                    <img className="mx-2" src='./img/partners/swast1.png'/>
                    </div>
                    </a>
                  <div className="product-content">
                <h6 style={{cursor: 'pointer', fontFamily: 'roboto condensed', fontWeight:'600', fontSize: '18px'}}><a style={{color: '#1D1A1A'}} href="https://appsinventor.com/products.html" target="_blank">SWAST Innovations Sdn. Bhd.</a></h6> 
                  </div>
              </div>
              </div>
           </div>
          </div>
       
         
        </div>
       </section>

{/* Our partners section end */}

  {/* our products section start */}

  <section id="ourproducts-section">
        <div className="ourproducts-details">
          <div className="ourproducts-title mb-5">
            <h4>Products and Services</h4>
          </div>
        
          <div className="slider">
            <div className="slider-track">
              <marquee width="90%" height="200px" scrolldelay="200" behavior="alternate" onmouseover="stop()" onmouseout="start()">
              <img src='./img/products/ats.png' alt="ats"/>
              <img src='./img/products/skubus.png' alt="skubus"/>
              <img src='./img/products/drhowma.png' alt="drhowma"/>
              <img src='./img/products/drjengu.png' alt="drjengu"/>
              <img src='./img/products/iotr.png' alt="iotr"/>
              <img src='./img/products/fr.png' alt="familyrecipe"/>
              <img src='./img/products/getzetgo.png' alt="getzetgo"/>
              
             </marquee>
             
            </div>
          </div>
          <div className="slider">
            <div className="slider-track">
              <marquee direction="right" width="90%" height="200px" scrolldelay="200" behavior="alternate" onmouseover="stop()" onmouseout="start()">
              <img className="ml-3" src='./img/products/mykyds.png' alt="mykyds"/>
              <img src='./img/products/kommino.png' alt="kommino"/>
              <img src='./img/products/nbe.png' alt="nearbyevent"/>
              <img src='./img/products/ea.png' alt="express avenue"/>
              <img src='./img/products/dbt.png' alt="dbt"/>
              <img src='./img/products/patanama.png' alt="patanama"/>
              <img src='./img/products/diemode.png' alt="diemode"/>
              <img className="mr-3" src='./img/products/unmaze.png' alt="unmaze"/>
             </marquee>
              
            </div>
          </div>
              <br/>
          <div className="product-btn my-4 text-center">
                <a href="https://appsinventor.com/products.html" target="_blank" className="product-list-btn text-uppercase">View more info &nbsp;<i className="fa fa-angle-double-right" aria-hidden="true"></i></a>
              </div>
          </div>
       </section>
     
     {/* our client section end */}

       {/* our client section start */}

       <section id="ourclient-section">
        <div className="ourclient-details">
          <div className="ourclient-title mb-5">
            <h4>Our clients</h4>
            <span  className="subtext-bottom">
            We have had the pleasure of working with some very successful companies across industries. Will your company be next on this list? 
            </span>
          </div>
          <div className="container"> <div className="row ourclient-logos">
            <div className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 p-0">
              <div className="logo-list">
                <img src='./img/brands/skubus-logo.png'/>
                </div>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 p-0">
              <div className="logo-list">
              <img src='./img/brands/MYKYDS-Logo.png'/>
                </div>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 p-0">
              <div className="logo-list">
              <img src='./img/brands/Getzetgo.png'/>
                </div>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 p-0">
              <div className="logo-list">
              <img src='./img/brands/Kommuno.png'/>
                </div>
            </div>
            

            <div className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 p-0">
              <div className="logo-list">
                <img src='./img/brands/ATS.png'/>
                </div>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 p-0">
              <div className="logo-list">
              <img src='./img/brands/Steding_Logo.png'/>
                </div>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 p-0">
              <div className="logo-list">
              <img src='./img/brands/patanama.png'/>
                </div>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 p-0">
              <div className="logo-list">
              <img src='./img/brands/dbt_logo.png'/>
                </div>
            </div>

          </div>
        

          </div>
         </div>
       </section>
     
     {/* our client section end */}


       {/* contact section start */}

        <section id="contact-section">
        <div className="contact-details">
          <div className="contact-title">
         
            <h4>Contact us</h4>
              <span  className="subtext-bottom">
              Looking to get an IT Partner? Get in touch with us 
            </span>
          </div>
          <div className="contact-info">
            <div className="company-name text-center">
             
              <h5>Telth Systems Private Limited</h5>
            </div>
            <div className="contact-data">
              <div className="row" style={{justifyContent:'center'}}>
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                  <div className="contact-text">
                    <h6>CONTACT</h6>
                    <div className="bottom-line my-2"></div>
                  </div>
                  <div className="contact-number">
                    <p className="mb-1">IN: <a href="tel:+917598883584">+91 720 00 TELTH</a></p>
                    <p className="mb-1">US: <a href="tel:+16122083584">+1 612 20 TELTH</a></p>
                    <p>UK: <a href="tel:+44 7864 104685">+44 7864 104685</a></p>
                    <p><i className="fa fa-envelope" aria-hidden="true"></i> &nbsp;<span>hello @ telth.com</span></p>        
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <div className="contact-text">
                    <h6>SUPPORT</h6>
                    <div className="bottom-line my-2"></div>
                  </div>
                  <div className="contact-number">
                     <p className="mb-1">IN: <a href="tel:+917200083584">+91 759 88 TELTH </a></p>
                   <p className="hidenumber"><a href="tel:+914479616591"></a></p>
                      <p><i className="fa fa-envelope supportmail" aria-hidden="true"></i> &nbsp;<span>support @ telth.com</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </section>
     
     {/* contact section end */}

       {/* footer section start */}

       <section className="footer-section">
          <footer className="footer-details">
          <div className="copyright-text">
                <div>
                <img className="my-2" src="./img/telth-logo.png" width="55px"/>
                </div>
                <span>TELTH SYSTEMS  © 2008-{yearFetch}</span>
              </div>
             <div className="social-media-icon" style={{display:'flex', justifyContent:'center'}}>
              <i className="fa fa-facebook facebook-footer" aria-hidden="true"></i>
              <i className="fa fa-twitter" aria-hidden="true"></i>
              <i className="fa fa-snapchat-ghost" aria-hidden="true"></i>
              <i className="fa fa-instagram" aria-hidden="true"></i>
              </div>
              
              <div className="termsofuse-text">
                <a href="" data-toggle="modal" data-target="#privacy-policy">Privacy policy</a>|
                 <a href="" data-toggle="modal" data-target="#terms-of-use">Terms of use</a>|
                  <a href="" data-toggle="modal" data-target="#cookie-policy">Cookie policy</a>
              </div>
          </footer>
       </section>
     
       <div className="modal fade" id="privacy-policy" role="dialog" tabindex="-1" aria-labelledby="Privacy-PolicyTitle" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    
    {/* Privacy Policy Contents */}
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title" style={{fontSize: '28px'}}><strong>Privacy Policy</strong></h1>
          <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        </div>
        <div className="modal-body">
     
        {/* Contents */}     

          <p>This Privacy Policy governs the manner in which Telth Systems collects, uses, maintains and discloses information collected from users (each, a “User”) of the <a href="http://telth.com/" target="_blank">www.telth.com</a> website (“Site”). This privacy policy applies to the Site and all products and services offered by Telth Systems.</p>
        
          <h1 style={{fontSize: '20px'}}><strong>Identification Information</strong> </h1>
        <p>We may collect personally identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, respond to a survey, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, phone number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</p>
        
        <h1 style={{fontSize: '20px'}}><strong> Non-personal Identification Information</strong> </h1>
        <p>We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.</p>
        
        <h1 style={{fontSize: '20px'}}><strong> Web Browser Cookies</strong> </h1>
        <p>Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
        
        <h1 style={{fontSize: '20px'}}><strong> How We Use Collected Information?</strong> </h1>
        <p>Telth Systems collects and uses Users personal information for the following purposes:</p>
        
        <h1 style={{fontSize: '20px'}}><strong> To personalize user experience</strong> </h1>
        <p>We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site</p>
        
        <h1 style={{fontSize: '20px'}}><strong> To improve our Site</strong> </h1>
        <p>We continually strive to improve our website offerings based on the information and feedback we receive from you</p>
        
        <h1 style={{fontSize: '20px'}}><strong> To improve customer service</strong> </h1>
        <p>Your information helps us to more effectively respond to your customer service requests and support needs</p>
        
        <h1 style={{fontSize: '20px'}}><strong> To send periodic emails/SMS</strong> </h1>
        <p>The email address or mobile number Users provide will only be used to respond to their inquiries, and/or other requests or questions. If User decides to opt-in to our mailing list or messsaging, they will receive emails/ messages that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving, they may do so by unsubscribing to the link provided in the email or sending message to the number or contacting us via our Site</p>
       
        <h1 style={{fontSize: '20px'}}><strong> How We Protect Your Information?</strong> </h1>
        <p style={{marginBottom: '.5em'}}>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.</p>
        <p>Our Site is in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users.</p>

        <h1 style={{fontSize: '20px'}}><strong> Sharing Your Personal Information</strong> </h1>
        <p>We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</p>
       
        <h1 style={{fontSize: '20px'}}><strong> Compliance With Children’s Online Privacy Protection Act</strong> </h1>
        <p>Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our Site from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.</p>
       
        <h1 style={{fontSize: '20px'}}><strong> Changes To This Privacy Policy</strong> </h1>
        <p>Telth Systems has the discretion to update this privacy policy at any time. When we do, we will post a notification on the main page of our Site, revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>
       
        <h1 style={{fontSize: '20px'}}><strong> Your Acceptance of These Terms</strong> </h1>
        <p>By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
       
      </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary close-btn" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>

   {/* Terms and Conditions */}
<div className="modal fade" id="terms-of-use" role="dialog" tabindex="-1" aria-labelledby="Terms-and-ConditionsTitle" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    
     {/* Terms and Conditions Contents*/}
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title" style={{fontSize: '28px'}}><strong>Terms of use</strong></h1>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        <div className="modal-body">
     
     {/*Contents*/}   

     <h1 style={{fontSize: '20px'}}><strong> Introduction</strong> </h1>
     <p style={{marginBottom: '.5em'}}>These terms and conditions govern your use of this website; by using this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.</p>
     <p>This website uses cookies. By using this website and agreeing to these terms and conditions, you consent to our Telth Systems use of cookies in accordance with the terms of Telth Systems cookies policy.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> License to use website</strong> </h1>
     <p style={{marginBottom: '.5em'}}>Unless otherwise stated, Telth Systems and/or its licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.</p>
     <p>This website uses cookies. By using this website and agreeing to these terms and conditions, you consent to our Telth Systems use of cookies in accordance with the terms of Telth Systems cookies policy.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Acceptable use</strong> </h1>
     <p style={{marginBottom: '.5em'}}>You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
     <p style={{marginBottom: '.5em'}}>You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</p>
     <p>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without Telth Systems express written consent.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Restricted access</strong> </h1>
     <p style={{marginBottom: '.5em'}}>Access to certain areas of this website is restricted. Telth Systems reserves the right to restrict access to other areas of this website, or indeed this entire website, at Telth Systems discretion</p>
     <p>If Telth Systems provides you with a user ID and password to enable you to access restricted areas of this website or other content or services, you must ensure that the user ID and password are kept confidential.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> User content</strong> </h1>
     <p style={{marginBottom: '.5em'}}>In these terms and conditions, “your user content” means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to this website, for whatever purpose.</p>
     <p style={{marginBottom: '.5em'}}>You grant to Telth Systems a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute your user content in any existing or future media. You also grant to Telth Systems the right to sub-license these rights, and the right to bring an action for infringement of these rights.</p>
     <p style={{marginBottom: '.5em'}}>Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or Telth Systems or a third party (in each case under any applicable law).</p>
     <p style={{marginBottom: '.5em'}}>You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint</p>
     <p>Telth Systems reserves the right to edit or remove any material submitted to this website, or stored on Telth Systems servers, or hosted or published upon this website</p>
     
     <h1 style={{fontSize: '20px'}}><strong> No warranties</strong> </h1>
     <p style={{marginBottom: '.5em'}}>This website is provided “as is” without any representations or warranties, express or implied. Telth Systems makes no representations or warranties in relation to this website or the information and materials provided on this website.</p>
     <p style={{marginBottom: '.5em'}}>Without prejudice to the generality of the foregoing paragraph, Telth Systems does not warrant that</p>
     <p style={{marginBottom: '.5em'}}>this website will be constantly available, or available at all; or</p>
     <p style={{marginBottom: '.5em'}}>the information on this website is complete, true, accurate or non-misleading.</p>
     <p>Nothing on this website constitutes, or is meant to constitute, advice of any kind.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Limitations of liability</strong> </h1>
     <p style={{marginBottom: '.5em'}}>Telth Systems will not be liable to you (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website:</p>
     <p style={{marginBottom: '.5em'}}>for any indirect, special or consequential loss; or</p>
     <p style={{marginBottom: '.5em'}}>for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.</p>
     <p>These limitations of liability apply even if Telth Systems has been expressly advised of the potential loss.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Exceptions</strong> </h1>
     <p style={{marginBottom: '.5em'}}>Nothing in this website disclaimer will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit; and nothing in this website disclaimer will exclude or limit Telth Systems liability in respect of any:</p>
     <p style={{marginBottom: '.5em'}}>death or personal injury caused by Telth Systems negligence;</p>
     <p style={{marginBottom: '.5em'}}>fraud or fraudulent misrepresentation on the part of Telth Systems; or</p>
     <p>matter which it would be illegal or unlawful for Telth Systems to exclude or limit, or to attempt or purport to exclude or limit, its liability.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Reasonableness</strong> </h1>
     <p style={{marginBottom: '.5em'}}>By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable.</p>
     <p>If you do not think they are reasonable, you must not use this website</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Unenforceable provisions</strong> </h1>
     <p>If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this website disclaimer.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Indemnity</strong> </h1>
     <p>You hereby indemnify Telth Systems and undertake to keep Telth Systems indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by Telth Systems to a third party in settlement of a claim or dispute on the advice of Telth Systems legal advisers) incurred or suffered by Telth Systems arising out of any breach by you of any provision of these terms and conditions.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Breaches of these terms and conditions</strong> </h1>
     <p>Without prejudice to Telth Systems other rights under these terms and conditions, if you breach these terms and conditions in any way, Telth Systems may take such action as Telth Systems deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Variation</strong> </h1>
     <p>Telth Systems may revise these terms and conditions from time-to-time. Revised terms and conditions will apply to the use of this website from the date of the publication of the revised terms and conditions on this website. Please check this page regularly to ensure you are familiar with the current version.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Assignment</strong> </h1>
     <p style={{marginBottom: '.5em'}}>Telth Systems may transfer, sub-contract or otherwise deal with Telth Systems rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.</p>
     <p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Severability</strong> </h1>
     <p>If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Entire Agreement</strong> </h1>
     <p>These terms and conditions constitute the entire agreement between you and Telth Systems in relation to your use of this website or any application stated, and supersede all previous agreements in respect of your use of this website.</p>
     
     <h1 style={{fontSize: '20px'}}><strong> Law and Jurisdiction</strong> </h1>
     <p>These terms and conditions will be governed by and construed in accordance with the laws of India, and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of India.</p>
     
      </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary close-btn" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>

     {/* cookie policy */}
<div className="modal fade" id="cookie-policy" role="dialog" tabindex="-1" aria-labelledby="Privacy-PolicyTitle" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    
       {/* cookie policy Contents*/}
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title" style={{fontSize: '28px'}}><strong>Cookie Policy</strong></h2>
          <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        </div>
        <div className="modal-body">
     
      {/*Contents*/}     

          <p>This is the Cookie Policy for Telth systems, accessible from <a href="http://www.telth.com" target="_blank">www.telth.com</a></p>
        
          <h1 style={{fontSize: '20px'}}><strong> What Are Cookies</strong> </h1>
        <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</p>
        <p>For more general information on cookies see the Wikipedia article on HTTP Cookies.</p>

        <h1 style={{fontSize: '20px'}}><strong> How We Use Cookies</strong> </h1>
        <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>
        
        <h1 style={{fontSize: '20px'}}><strong> Disabling Cookies</strong> </h1>
        <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>
        
        <h1 style={{fontSize: '20px'}}><strong> The Cookies We Set</strong> </h1>
        <ul>
                <li>
                    <p>Site preferences cookies</p>
                    <p>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</p>
                </li> 
        </ul>
        
        <h1 style={{fontSize: '20px'}}><strong> Third Party Cookies</strong> </h1>
        <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>
        
        <ul>
                <li>
                        <p>From time to time we test new features and make subtle changes to the way that the site is delivered. When we are still testing new features these cookies may be used to ensure that you receive a consistent experience whilst on the site whilst ensuring we understand which optimisations our users appreciate the most.</p>
                    </li>
                    <li>
                            <p>The Google AdSense service we use to serve advertising uses a DoubleClick cookie to serve more relevant ads across the web and limit the number of times that a given ad is shown to you.</p>
                            <p>For more information on Google AdSense see the official Google AdSense privacy FAQ.</p>
                        </li>
                        <li>
                            <p>We also use social media buttons and/or plugins on this site that allow you to connect with your social network in various ways. For these to work the following social media sites including; {curlyOpen} List the social networks whose features you have integrated with your site?:12{curlyClose}, will set cookies through our site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.</p>
                        </li>
        </ul>

        <h1 style={{fontSize: '20px'}}><strong> More Information</strong> </h1>
        <p>However if you are still looking for more information then you can contact us through one of our preferred contact methods:</p>
        
        <ul>
                <li>Email: <a href="mailto:hello@telth.com">hello@telth.com</a></li>
                <li>By visiting this link: <a href="http://telth.com/" target="_blank">www.telth.com</a></li>
                </ul>
      </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary close-btn" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>

     {/* footer section end */}

    </div>
  );
}

export default App;
